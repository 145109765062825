import { Store, stores } from '@strategies/stores';
import { action, computed, observable, makeObservable } from 'mobx';
import { Timespan, time } from '@strategies/react-timeline';

import { randomString } from '../util';
import DashiChart from '../ui/charts/Chart';
import DashiMetric from '../models/Metric';
import DashiPeriod from '../models/Period';
import DashiProject, {IProjectData, TimelineStatus} from '../models/Project';


const MONTH = 10;

type MetricMap = {
    [key: string]: DashiMetric;
}

type FilterState = { [key: string]: string[] };


export default class DashiAppStore extends Store {
    metrics: MetricMap = {};

    constructor() {
        super();

        makeObservable(this);
    }

    onRegister() {
        for (let i = 0; i < stores.config.totalPeriods; i++) {
            this.periods.push(new DashiPeriod(i));
        }

        this.triggerBlockSort();
    }

    charts: typeof DashiChart[] = [];

    @observable
    colorMode: string = 'type';

    @action
    setColorMode(mode: string) {
        this.colorMode = mode;
    }

    @observable
    timelineEnabled: boolean = true;

    @action
    setTimelineEnabled(value: boolean) {
        this.timelineEnabled = value;
    }

    @observable
    periods: DashiPeriod[] = [];

    @observable
    scrubber: number = MONTH * time.MONTH;

    @action
    setScrubber(scrubber: number) {
        this.scrubber = scrubber;
    }

    @observable
    activeBySelection: boolean = false;

    @action
    setActiveBySelection(bySelection: boolean = true) {
        this.activeBySelection = bySelection;
    }

    @computed
    get activeProjects() {
        // @ts-ignore
        return this.activeBySelection ? this.selectedProjects : this.projects.filter(project => !project.isExcluded && project.timelineStatus === TimelineStatus.Completed);
    }


    @computed
    get includedProjects() {
        return this.projects.filter((project: DashiProject) => !project.isExcluded)
    }

    @computed
    get currentPeriod() {
        const { periodScale } = stores.config;

        let period = Math.floor(this.scrubber / periodScale);

        if (period < 0) {
            period = 0;
        }
        else if (period >= this.periods.length) {
            period = this.periods.length - 1;
        }

        return this.periods[period];
    }

    @observable
    filters: FilterState = {};

    @action
    setFilters(filters: FilterState) {
        this.filters = filters;
        this.triggerBlockSort();
    }

    @computed
    /**
     *  @returns IDashiProject[] - only projects that pass the filter criteria.
     *  Use persist.projects to return all projects
     */
    get projects() {
        const { persist } = stores;

        return persist.projects.filter((project: DashiProject) => {
            let filtered = true;

            if (project.type === "Existing") {//TODO REFACTOR: move this logic to skin
                filtered = false;
            }
            else {
                Object.entries(this.filters).some(([attr, values]) => {
                    // @ts-ignore
                    if (values.indexOf(project[attr]) !== -1) {
                        filtered = false;
                    }
                    return !filtered;
                });
            }

            return filtered;
        });
    }

    createProject(data: IProjectData) {
        return new DashiProject(data);
    }

    createProjectFromBlock(block: Timespan) {
        return this.createProject({
            id: randomString(20),
            name: 'New Project',
            mapped: false,
            isExcluded: false,
            timestamps: {
                design: block.start,
                // The default design period length will be 25% of the new block duration.
                start: ((block.end - block.start) * .25) + block.start,
                end: block.end,
            }
        });
    }

    @action
    selectAndEditProject(projectToSelect: DashiProject) {
        this.selectProject(projectToSelect);
        this.selectedProject.snapshot();
        this.ui.setProjectSettingsOpen(true);
    }

    @action
    selectProject(projectToSelect: DashiProject, augmentSelection:boolean = false) {
        if(augmentSelection) {
            projectToSelect.setSelected(!projectToSelect.selected);
            return;
        }
        this.selectedProjects.forEach((project: DashiProject) => {
            project.setSelected(projectToSelect === project);
        });
    }

    @computed
    get projectsKey() {
        return this.includedProjects.map((project: DashiProject )=> project.$modelId).reduce((a: string, b: string) => a + b, '');
    }

    @computed
    get selectedProjects() {
        return stores.persist.projects.filter((project: DashiProject) => project.selected);
    }

    @computed
    get selectedProject() {
        return this.selectedProjects[0];
    }

    @computed
    get periodLabels() {
        return this.periods.map(period => period.label);
    }

    triggerBlockSort() {
        const { config } = stores;

        [ ...this.includedProjects ].sort((a,b) => this.sortBlocks(a,b)).forEach((project, i) => {
            project.setTimelineY(i * (config.timelineBlockHeight + config.timelineRowPadding));
        });
    }

    sortBlocks(a: DashiProject, b: DashiProject) {
        return a.timestamps.start === b.timestamps.start ? 0 : a.timestamps.start < b.timestamps.start ? -1 : 1;
    }

}
