import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';
import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Panel, Title, Toggle, Body, Header }  from '@strategies/ui';
import { CheckboxSlider } from "@strategies/react-form";
import { FiTrendingUp } from 'react-icons/fi';

import { fixedRectStyle } from "../../stores/LayoutStore";


export default observer(function ChartsPanel() {
    const { app, layout, ui } = useStores();
    const { charts } = app;

    const isDisabled = chart => (
        chart.isDisabled
        || (
            layout.innerChartArea.size.width < chart.requiredWidth + layout.requiredChartsWidth
            && !ui.isChartSelected(chart)
        )
    );

    return (
        <div className="ChartsPanel" style={fixedRectStyle(layout.chartsPanel)}>
            <Panel
                active={ui.chartsPanelIsOpen}
                onToggle={() => ui.setChartsPanelOpen(!ui.chartsPanelIsOpen)}
            >
                <Title>Analysis</Title>
                <Toggle><FiTrendingUp /></Toggle>

                <Header>
                    <div className="chartFilter">
                        {!ui.chartsPanelIsOpen || layout.chartsPanel.size.width < 1100 ? <>
                            <Menu className="filter" menuButton={<MenuButton>SELECT CHARTS</MenuButton>}>
                                {charts.map(Chart => (
                                    <MenuItem
                                        key={Chart.label}
                                        type="checkbox"
                                        disabled={isDisabled(Chart)}
                                        checked={ui.isChartSelected(Chart)}
                                        onClick={() =>  ui.setChartSelection(Chart, !ui.isChartSelected(Chart))}
                                    >
                                        {Chart.label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </> : <>
                            {charts.map(Chart => (
                                <div key={Chart.label} className={"filter indChartsLabel" + (isDisabled(Chart) ? ' disabled' : '')}>
                                    <label htmlFor={Chart.label}>
                                        <input
                                            id={Chart.label}
                                            name={Chart.label}
                                            type="checkbox"
                                            value={Chart.label}
                                            disabled={isDisabled(Chart)}
                                            checked={ui.isChartSelected(Chart)}
                                            onChange={() => ui.setChartSelection(Chart, !ui.isChartSelected(Chart))}
                                        />

                                        <div className={"chartTitle"}>
                                            {Chart.label}
                                        </div>
                                    </label>
                                </div>
                            ))}
                        </>}

                        <div className="filter filter-toggle">
                            SELECTED ONLY
                            <CheckboxSlider
                                value={app.activeBySelection}
                                onChange={val => app.setActiveBySelection(val)}
                            />
                        </div>
                    </div>
                </Header>

                <Body>
                    {((!app.activeBySelection && app.includedProjects.length > 0) || (app.activeBySelection && app.selectedProjects.length > 0)) ? (
                        <div className="charts" style={{ height: layout.innerChartArea.size.height }}>
                            {layout.charts.map(slot => (
                                slot.chart && (
                                    <div key={slot.chart.label} className="ChartWrapper" style={slot.rect || {}}>
                                        <slot.chart
                                        />
                                    </div>
                                )
                            ))}
                        </div>
                    ) : (
                        <div className="charts-placeholder">
                            <div>
                                No {app.activeBySelection && "Selected "}Projects
                            </div>
                        </div>
                    )}
                </Body>
            </Panel>
        </div>
    );
});
