import { stores } from '@strategies/stores';
import NumberFormat from 'react-number-format';

import Project from "../models/Project";
import DashiCostMetric from '../../../core/metrics/Cost';
import CUBSpaceTypeMetric from './SpaceType';
import DashiProject from '../../../core/models/Project';


export default class CostMetric extends DashiCostMetric  {

    //TODO where should this logic go?

    generatePayload(cost: number) {
        const payload: any = super.generatePayload(cost);
        payload.constructionCost = payload.cost * .71;
        return payload;
    }

    //TODO we need to refactor Metrics to move the settings to a component used in the skin

    renderSettings(project: DashiProject) {
        const { app, config } = stores;
        const { ProjectFundingColors } = config;
        const { cost, constructionCost } = project.metrics[this.name];
        const spaceTypeMetric = app.metrics['SpaceType'] as CUBSpaceTypeMetric;
        const spaceType = project.metrics['SpaceType'];

        const projectCUB = project as Project;

        return (
            <div className="CostMetricSettings ProjectSettings-section">
                <div className="ProjectSettings-entry">
                    <label htmlFor="ProjectFunding">Project Funding</label>
                    <select id="ProjectFunding" value={projectCUB.funding} onChange={e => projectCUB.setFunding(e.target.value)}>
                        {Object.keys(ProjectFundingColors).map((fund: string) => (
                            <option value={fund}>{fund}</option>
                        ))}
                    </select>
                </div>

                <div className="ProjectSettings-entry">
                    <label htmlFor="ProjectCost">Project Cost</label>
                    <div className="ProjectSettings-input">
                        <div className="ProjectSettings-entry-col">
                            <div className="labelled-input cost">
                                <label htmlFor="ProjectCost">$</label>
                                <NumberFormat
                                    id="ProjectCost"
                                    thousandSeparator={true}
                                    value={cost}
                                    onValueChange={(e: any) => project.setMetric(this.name, this.generatePayload(e.floatValue || 0))}
                                />
                            </div>

                            <div className="labelled-input readonly">
                                <label>$</label>
                                <div className="cell-value">
                                    {(
                                        project.type === "Demolition" ? (
                                            projectCUB.gsf > 0 ? cost / spaceTypeMetric.gsf(spaceType.before, projectCUB.gsf) : 0
                                        ) : (
                                            projectCUB.gsf > 0 ? cost / spaceTypeMetric.gsf(spaceType.after, projectCUB.gsf) : 0
                                        )
                                    ).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                </div>
                                <span>/GSF</span>
                            </div>
                        </div>

                        <div className="ProjectSettings-entry-col" style={{ margin: 'auto' }}>
                            in
                        </div>

                        <div className="ProjectSettings-entry-col escalation-year">
                            <div className="labelled-input">
                                <input
                                    id="EscalationYear"
                                    value={projectCUB.escalationYear}
                                    onChange={e => projectCUB.setEscalationYear(parseInt(e.target.value))}
                                />
                                <label htmlFor="EscalationYear">Dollars</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ProjectSettings-entry">
                    <label>Construction Cost</label>
                    <div className="ProjectSettings-input">
                        <div className="labelled-input readonly cost">
                            <span>$</span>
                            <div className="cell-value">
                                {Math.round(constructionCost).toLocaleString()}
                            </div>
                        </div>

                        <div className="labelled-input readonly">
                            <span>$</span>
                            <div className="cell-value">
                                {(
                                    project.type === "Demolition" ? (
                                        projectCUB.gsf > 0 ? constructionCost / spaceTypeMetric.gsf(spaceType.before, projectCUB.gsf) : 0
                                    ) : (
                                        projectCUB.gsf > 0 ? constructionCost / spaceTypeMetric.gsf(spaceType.after, projectCUB.gsf) : 0
                                    )
                                ).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            </div>
                            <span>/GSF</span>
                        </div>
                    </div>
                </div>

               <div className="ProjectSettings-entry">
                    <label htmlFor="Escalation">Escalation</label>
                    <div className="ProjectSettings-input">
                        <div className="labelled-input escalation">
                            <NumberFormat
                                id="Escalation"
                                thousandSeparator={true}
                                value={projectCUB.escalation}
                                onValueChange={(e: any) => projectCUB.setEscalation(e.floatValue || 0)}
                            />
                            <label htmlFor="Escalation">%</label>
                        </div>

                        <label>Compounded Escalation</label>
                        <div className="labelled-input readonly">
                            <div className="cell-value">
                                {(projectCUB.compoundedEscalation * 100).toFixed(2)}
                            </div>
                            <span>%</span>
                        </div>
                    </div>
                </div>

               <div className="ProjectSettings-entry">
                    <label>Escalated <br/>Project Cost</label>
                    <div className="ProjectSettings-input">
                        <div className="ProjectSettings-entry-col">
                            <div className="labelled-input readonly cost">
                                <span>$</span>
                                <div className="cell-value">
                                    {Math.round(projectCUB.escalatedProjectCost).toLocaleString()}
                                </div>
                            </div>

                            <div className="labelled-input readonly">
                                <span>$</span>
                                <div className="cell-value">
                                    {(
                                        project.type === "Demolition" ? (
                                            projectCUB.gsf > 0 ? projectCUB.escalatedProjectCost / spaceTypeMetric.gsf(spaceType.before, projectCUB.gsf) : 0
                                        ) : (
                                            projectCUB.gsf > 0 ? projectCUB.escalatedProjectCost / spaceTypeMetric.gsf(spaceType.after, projectCUB.gsf) : 0
                                        )
                                    ).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                </div>
                                <span>/GSF</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ProjectSettings-entry">
                    <label>Escalated <br/>Construction Cost</label>
                    <div className="ProjectSettings-input">
                        <div className="labelled-input readonly cost">
                            <span>$</span>
                            <div className="cell-value">
                                {Math.round(projectCUB.escalatedConstructionCost).toLocaleString()}
                            </div>
                        </div>

                        <div className="labelled-input readonly">
                            <span>$</span>
                            <div className="cell-value">
                                {(
                                    project.type === "Demolition" ? (
                                        projectCUB.gsf > 0 ? projectCUB.escalatedConstructionCost / spaceTypeMetric.gsf(spaceType.before, projectCUB.gsf) : 0
                                    ) : (
                                        projectCUB.gsf > 0 ? projectCUB.escalatedConstructionCost / spaceTypeMetric.gsf(spaceType.after, projectCUB.gsf) : 0
                                    )
                                ).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                            </div>
                            <span>/GSF</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
