import { Block } from '@strategies/react-timeline';

import DashiAppStore from '../../../core/stores/AppStore';

import data from '../assets/projects.json';
import CUBProject, { ICUBProjectData } from '../models/Project';

import CostMetric from '../metrics/Cost';
import SpaceTypeMetric from '../metrics/SpaceType';

import UseByASF from '../charts/UseByASF';
import TypeByASF from '../charts/TypeByASF';
import TypeDonut from '../charts/TypeDonut';
import FundingByASF from '../charts/FundingByASF';
import FundingByCost from '../charts/FundingByCost';
import UseDonut from "../charts/UseDonut";

export default class CUBAppStore extends DashiAppStore {

    constructor() {
        super();

        const Cost = new CostMetric('Construction Cost');

        const SpaceType = data.reduce((metric, project) => {
            Object.values(project.metrics.payloads).forEach(payload => {
                if (payload.spaceType && metric.options.indexOf(payload.spaceType) === -1) {
                    metric.addOption(payload.spaceType);
                }
            });

            return metric;
        }, new SpaceTypeMetric('Space Type'));

        this.metrics = {
            [Cost.name]: Cost,
            [SpaceType.name]: SpaceType,
        };

        this.charts = [
            UseByASF,
            TypeByASF,
            FundingByCost,
            FundingByASF,
            // DebugChart,
            TypeDonut,
            UseDonut
        ];
    }

    createProject(data: ICUBProjectData) {
        return new CUBProject(data);
    }

    createProjectFromBlock(block: typeof Block) {
        const project = super.createProjectFromBlock(block) as CUBProject;
        project.setFunding('Capital General');

        return project;
    }

}
