import { observer } from 'mobx-react';
import { useStores } from '@strategies/stores';

import Login from './ui/Login';
import WelcomeModal from './ui/WelcomeModal';
import MenuBar from './../../core/ui/MenuBar';
import FileModal from './../../core/ui/FileModal';
import InfoModal from './../../core/ui/InfoModal';
import ChartsPanel from './../../core/ui/ChartsPanel';
import SaveAsModal from './../../core/ui/SaveAsModal';
import UpdateScenarioModal from '../../core/ui/UpdateScenarioModal';
import TimelinePanel from './../../core/ui/TimelinePanel';
import ProjectSettings from './ui/ProjectSettings';
import ColorizerStage from './../../core/ui/Colorizer/components/ColorizerStage';
import DisplaySelectionInfo from '../cu-boulder/ui/DisplaySelectionInfo';


export default observer(function DashiApp() {
    const { user } = useStores();

    return (
        <div className="DashiApp">
            {!(user.isLoggedIn && user.isVerified) ? (
                <Login />
            ) : <>
                <WelcomeModal />
                <InfoModal />
                <SaveAsModal />
                <UpdateScenarioModal />
                <FileModal />

                <ColorizerStage />
                <ProjectSettings />
                <DisplaySelectionInfo />

                <div className="panels">
                    <MenuBar />
                    <TimelinePanel />
                    <ChartsPanel />
                </div>
            </>}
        </div>
    );
});
