import { stores } from '@strategies/stores';
import { v4 as uuidv4 } from 'uuid';
import {ReactNode} from "react";
import {computed, makeObservable} from "mobx";

import DashiColorizerState from "./Colorizer";
import DashiProject from "./Project";
import {LayerIdCanvas} from "../ui/Colorizer/components/LayerIdCanvas";
import ColorizerCanvas from "../ui/Colorizer/components/ColorizerCanvas";


export interface IImageLayer {
    imageWidth: number;
    imageHeight: number;
    key: string;
    element: ReactNode;
}


export class ColorStateLayer implements IImageLayer {
    key = uuidv4();
    imageWidth = 0;
    imageHeight = 0;
    constructor(imageWidth: number, imageHeight: number) {
        makeObservable(this);
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
    }

    @computed
    get _colorizerStates(): Map<string, DashiColorizerState> {
        const {persist} = stores;
        const states = new Map<string, DashiColorizerState>();
        const {projects} = persist;//Note: this is the full project list (not filtered)

        projects.filter((project: DashiProject) => project.visible).forEach((project: DashiProject) => {
            states.set(project.id, project.colorizerState);
            /*
            states.set(project.id, new DashiColorizerState({
                color: '#ff0000',
                timelineStatus: TimelineStatus.Completed,
                isExcluded: false,
                includedInCharts: false,
                filteredOut: false,
                selected: false;
            }));
             */
        });
        return states;
    }

    @computed
    get colorizerStates(): Map<string, DashiColorizerState> {
        return this._colorizerStates;
    }

    get element():ReactNode {
        return null;
    }
}

export class ColorizerLayer extends ColorStateLayer {
    paths?: {
        graphic: string;
        data: string;
        key: string[];
    }

    constructor(imageWidth: number, imageHeight: number, graphic: string, data: string, key: string[]) {
        super(imageWidth, imageHeight)
        makeObservable(this);
        this.paths = {
            graphic: graphic,
            data: data,
            key: key,
        }
    }

    get element() {
        return <ColorizerCanvas canvasId={'colorizer'} layer={this} width={this.imageWidth}
                                height={this.imageHeight}/>
    }
}

export class GraphicIdLayer extends ColorStateLayer  {
    paths?: {
        graphic: string;
        id: string;
        key: string[];
    }

    constructor(imageWidth: number, imageHeight: number, graphic: string, id: string, key: string[]) {
        super(imageWidth, imageHeight)
        makeObservable(this);
        this.paths = {
            graphic: graphic,
            id: id,
            key: key,
        }
    }

    get element():ReactNode {
        return <LayerIdCanvas canvasId={'graphic-id'} layer={this} width={this.imageWidth}
                              height={this.imageHeight}/>
    }
}


export class ImageLayer implements IImageLayer {
    readonly key = uuidv4();
    readonly imagePath:string = '';
    readonly imageWidth:number = 0;
    readonly imageHeight:number = 0;

    constructor(imagePath: string, imageWidth: number, imageHeight: number) {
        this.imagePath = imagePath;
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
    }

    get element():ReactNode {
        return <img src={`${this.imagePath}`} width={this.imageWidth} height={this.imageHeight}
                    alt={'underlay graphic'}/>
    }
}
