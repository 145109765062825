/* eslint-disable no-useless-escape */
import React from "react";
import createREGL from "regl";
import PixelReader from "../helpers/PixelReader";
import {autorun} from 'mobx';
import REGL from "regl";


const vert = `
    precision mediump float;
    attribute vec2 position;
    varying vec2 uv;
    void main () {
        uv = 0.5 * (position + 1.0);
        gl_Position = vec4(position, 0, 1);
    }
`;
export const glslCore = () => {
// language=GLSL
    return `

        //https://github.com/jamieowen/glsl-blend/blob/master/screen.glsl
        float blendScreen(float base, float blend) {
            return 1.0-((1.0-base)*(1.0-blend));
        }

        vec3 blendScreen(vec3 base, vec3 blend) {
            return vec3(blendScreen(base.r, blend.r), blendScreen(base.g, blend.g), blendScreen(base.b, blend.b));
        }

        //https://shaderfrog.com/app/view/2756
        vec3 fractalish(vec3 color, float scale, float s, float xOffset, float yOffset, float m1, float width, float posterize) {
            float x = floor(gl_FragCoord.x * scale);
            float y = floor(gl_FragCoord.y * scale);
            float m = mod((y - yOffset) * (x - xOffset) / s, width) / m1;

            if (posterize < 4.) {
                m = floor(m * posterize + 0.5) / posterize;
            }
            return color / clamp(m, 0.2, 1.);
        }

        bool hatchPrimary(int spacing, bool backslash, float scale) {
            float x = floor(gl_FragCoord.x * scale);
            float y = floor(gl_FragCoord.y * scale);
            float w = float(spacing);
            float diff = x - y;
            if (backslash) {
                diff = y + x;
            }
            float m = mod(diff, w);

            return m == 0.0;
        }
        bool hatchPrimary(int spacing, bool backslash) {
            return hatchPrimary(spacing, backslash, 1.5);
        }

        vec3 hatchColors(int spacing, vec3 color1, vec3 color2, bool backslash) {
            if (hatchPrimary(spacing, backslash)) {
                return color1;
            } else {
                return color2;
            }
        }

        vec4 hatchColors(int spacing, vec4 color1, vec4 color2, bool backslash) {
            if (hatchPrimary(spacing, backslash)) {
                return color1;
            } else {
                return color2;
            }
        }

        vec3 hatchColor(int spacing, vec3 color, float darken, bool backslash) {
            return hatchColors(spacing, color, color * darken, backslash);
        }

        vec4 styleFromAlphaEncoding(vec4 color, float alpha, float lightness) {
            //we use an alpha channel-based encoding for different styles when rendered from the 'current' color state canvas
            //0    = hidden
            if (alpha == 0.) return vec4(0.);

            if (alpha < 0.2) { //use this alpha space (0-0.2) to specify plain old alpha 0-100% divide intended % by 5 e.g. 25% opacity = 0.05
                return vec4(color.rgb, color.a * alpha / 0.2);
            }

            //0.2  = faded back (/)
            //0.25 = faded back (\)

            if (alpha <= 0.26) { //tolerance to allow fractional stuff
                if (lightness > 0.3) { //avoid hatching on 'edge' pixels
                    vec4 faded1 = vec4(color.rgb, 0.15);
                    vec4 faded2 = vec4(color.rgb, 0.65);
                    vec4 fadedHatch = hatchColors(4, faded1, faded2, alpha > 0.225);

                    return vec4(fadedHatch.rgb, fadedHatch.a * color.a);
                }
            }

            //0.5  = darken (/)
            //0.55 = darken (\)

            if (alpha <= 0.551) {
                return vec4(hatchColor(4, color.rgb, 0.5, alpha > 0.51), color.a);
            }

            if (alpha <= 0.851 && alpha >= 0.849) {
                vec3 paisley = fractalish(color.rgb, 1., 1.19683656*1.19683656, 226., 479.2125, 2., 3.14159265359, 0.65879031);
                if (lightness > 0.87) { //only 'lighten' the brighter faces so we maintain a 3D feel here
                    return vec4(blendScreen(paisley, color.rgb), 0.7);
                } else {
                    return vec4(blendScreen(paisley, color.rgb), 0.9);
                }
            } else {
                if (lightness > 0.87) { //only 'lighten' the brighter faces so we maintain a 3D feel here
                    //0.7  = lighten (/)
                    //0.75 = lighten (\)

                    if (alpha <= 0.751) {
                        return vec4(hatchColors(8, mix(vec4(1.), color * 1.5, 0.65), color * 0.9, alpha > 0.71).rgb, color.a);
                    }

                    //0.8  = fractalish

                    if (alpha <= 0.81) {
                        vec3 paisley = fractalish(color.rgb, 1., 1.19683656*1.19683656, 226., 479.2125, 2., 3.14159265359, 0.65879031);
                        return vec4(paisley, color.a);
                    }
                }
            }

            //1    = regular 100%
            return color;
        }

        float floor2(float val) {
            float rounded = float(int(val));
            if (rounded > val) {
                float ans = rounded - 1.;
                return ans;
            }

            return rounded;
        }

        float mod2(float n, float d) {
            if (d == 0.) {
                return 0.;
            }
            float ans = n - d * floor2(n / d);
            if (ans == d) {
                return 0.;
            }
            return ans;
        }

        int floorInt(float val) {
            float rounded = float(int(val));
            if (rounded > val) return int(rounded - 1.);
            return int(rounded);
        }
        int powInt(int n, int d) {
            return int(pow(float(n), float(d)));
        }
        int modInt(int n, int d) {
            if (d == 0) {
                return 0;
            }
            return n - d * floorInt(float(n) / float(d));
        }

        int ceilInt(float val) {
            float rounded = float(int(val));
            if (rounded < val) return int(rounded + 1.);
            return int(rounded);
        }

        int toValInt(vec4 texelColour) {
            float rVal = texelColour.r * 256.0 * 256.0 * 255.;
            float gVal = texelColour.g * 256.0 * 255.;
            float bVal = texelColour.b * 255.;
            return ceilInt(rVal + gVal + bVal);
        }

        int extractVal(inout int cVal, int rightPos, int length) {
            int position = rightPos;
            int prev = powInt(2, position);
            position += length;
            int mult = powInt(2, position);
            int decoded = modInt(cVal, mult);
            cVal -= decoded;
            int v = decoded / prev;
            return v;
        }

        vec4 blendColors(vec4 src, vec4 dst) { //standard alpha blending https://apoorvaj.io/alpha-compositing-opengl-blending-and-premultiplied-alpha/
            float final_alpha = src.a + dst.a * (1.0 - src.a);
            return vec4(
            (src.rgb * src.a + dst.rgb * dst.a * (1.0 - src.a)) / final_alpha,
            final_alpha
            );
        }

        float toVal(vec4 texelColour) {
            //                    float prec = 1.0;
            float val = texelColour.r * 256.0 * 256.0 + texelColour.g * 256.0 + texelColour.b;
            //                    val = val / prec;

            return val;
        }

        vec4 applyLightness(vec4 color, float lightness) {
            return vec4(
            color.r * lightness,
            color.g * lightness,
            color.b * lightness,
            color.a
            );
        }
    `;
};

function getMousePos(canvas: any, evt: any) {
    var rect = canvas.getBoundingClientRect();
    return {
        x: Math.floor((evt.clientX - rect.left) / (rect.width) * canvas.width),
        y: Math.floor((evt.clientY - rect.top) / (rect.height) * canvas.height)
    };
}

function dist(x1: number, x2: number, y1: number, y2: number) {
    const dx = x1 - x2;
    const dy = y1 - y2;

    return Math.sqrt(dx * dx + dy * dy)

}

export type ReglCanvasProps = { width: number, height: number };
type ReglCanvasState = {loaded: boolean, mouseX: number, mouseY: number, downX: number, downY: number}

export class ReglCanvas<T extends ReglCanvasProps> extends React.Component<T, ReglCanvasState> {
    canvasRef:React.RefObject<HTMLCanvasElement> = React.createRef()
    loadedTextures: { [key: string]: any };
    canvas: any;
    regl?: REGL.Regl;
    nArr: number = 0;
    keyIndex: string[] = [];
    width: number = 0;
    height: number = 0;
    idImage?: any;
    pixelReader?: PixelReader;

    constructor(props: any) {
        super(props);
        this.loadedTextures = {};
        this.state = {loaded: false, mouseX: 0, mouseY: 0, downX: 0, downY: 0}
    }

    drawConfig(regl: any, vert: string): any {//note: using type of 'any' not REGL.Regl here as regl uses "prop" indexing that doesn't work well with their TS implementation
        throw new Error('Must be implemented in subclass');
    }

    loadKeyIndex(): string[] {
        throw new Error('Must be implemented in subclass');
    }

    drawFrameProps(): any {
        throw new Error('Must be implemented in subclass');
    }

    async loadImages(keyIndex: string[]) {
        throw new Error('Must be implemented in subclass');
    }

    withHitPixel(r:number, g:number, b:number, a:number, ctrlKey:boolean, shiftKey:boolean) {
        //optional override
    }

    componentDidMount() {
        this.canvas = this.canvasRef.current;
        this.setSize();
        window.addEventListener("resize", this.setSize);
        this.regl = createREGL({
            canvas: this.canvas, attributes: {
                antialias: false,
                alpha: true,
                premultipliedAlpha: false,
            },
        });
        const {regl} = this;

        const fetchData = async () => {
            const keyIndex = this.loadKeyIndex();
            keyIndex.push('');//last element doesn't work too well, so we add a fake element at the end...
            this.nArr = keyIndex.length;
            this.keyIndex = keyIndex;
            await this.loadImages(keyIndex);
        }

        fetchData().then(() => {
            // setLoaded(true);
            const drawFrame = regl(this.drawConfig(regl, vert));

            autorun(
                () => {
                    let frameProps = this.drawFrameProps();
                    if (frameProps) {
                        regl.clear({depth: 1});//TODO transparency support

                        drawFrame(frameProps);
                    }
                }
            );

            this.setState({loaded: true});

        });
    }

    componentWillUnmount() {
        this.regl?.destroy();
        //TODO remove colorStateImage canvas
        window.removeEventListener("resize", this.setSize);
    }

    setSize = () => {//lambda for easy event binding
        const {canvas} = this;
        canvas.width = this.width = canvas.clientWidth;
        canvas.height = this.height = canvas.clientHeight;
    }

    async loadFromSrc(src:string):Promise<any> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = src;
            image.onload = function () {
                resolve(image);
            };
        });
    };

    checkForHit(e:any) {
        if (!this.canvas) return;
        const {x, y} = getMousePos(this.canvas, e);
        const {downX, downY} = this.state;
        const dragTol = 5;
        if (dist(e.clientX, downX, e.clientY, downY) > dragTol) {
            return;
        }
        if (!this.idImage) return;

        const px = this.readPx(this.idImage, x, y);
        if (px) {
            this.withHitPixel(px[0], px[1], px[2], px[3], e.ctrlKey, e.shiftKey);
        }
    }


    readPx(image:CanvasImageSource, x: number, y: number) {
        if (!image) return;
        if (!this.pixelReader) {
            this.pixelReader = new PixelReader();
        }
        return this.pixelReader.readPixel(image, x, y);
    }

    render() {
        const {width, height} = this.props;
        return (
            <>
                <canvas
                    ref={this.canvasRef}
                    style={{width, height}}
                    onClick={(e) => {
                        this.checkForHit(e);
                    }}

                    onMouseDown={(e) => {
                        this.setState({downX: e.clientX, downY: e.clientY});
                    }}

                    onMouseMove={(e) => {
                        const {x, y} = getMousePos(this.canvas, e);
                        this.setState({mouseX: x, mouseY: y});
                    }}
                />
            </>
        );
    }
}
