const firebaseConfig = {
    apiKey: "AIzaSyAR85668cVeywAI9nwLzkrCay2QRU28vVI",
    authDomain: "bpy-dashi-demo.firebaseapp.com",
    projectId: "bpy-dashi-demo",
    storageBucket: "bpy-dashi-demo.appspot.com",
    messagingSenderId: "587392231278",
    appId: "1:587392231278:web:a592ccd4d57c2b7522437f"
};

export default firebaseConfig;
